import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Alert } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { db } from "../../shared/firebase";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import { useAuth } from "../../shared/useAuth";
import ContainerBox from "../../shared/ContainerBox";
import { Helmet } from "react-helmet";
import axios from "axios";
import QuestionnaireForm from "../Questionnaire/QuestionnaireForm";
import { colors } from "../lib/theme";
import { Link } from "react-router-dom";

const relationshipOptions = [
  { us: "Mom", uk: "Mum" },
  { us: "Dad", uk: "Dad" },
  { us: "Spouse", uk: "Spouse" },
  { us: "Grandma", uk: "Nan" },
  { us: "Grandpa", uk: "Grandad" },
  { us: "Partner", uk: "Partner" },
  { us: "Sibling", uk: "Sibling" },
  { us: "Son", uk: "Son" },
  { us: "Daughter", uk: "Daughter" },
  { us: "Niece", uk: "Niece" },
  { us: "Nephew", uk: "Nephew" },
  { us: "Cousin", uk: "Cousin" },
  { us: "Uncle", uk: "Uncle" },
  { us: "Aunt", uk: "Auntie" },
  { us: "Good Friend", uk: "Good Friend" },
  { us: "Other", uk: "Other" },
];

const occasionOptions = [
  "Birthday",
  "Anniversary",
  "Graduation",
  "Retirement",
  "Holiday",
  "Just Because",
  "Other",
];

const CreateAlbum = ({ config }) => {
  const { user, loading } = useAuth();
  const [newAlbumTitle, setNewAlbumTitle] = useState("");
  const [isGeneratingTracks, setIsGeneratingTracks] = useState(false);
  const [generatedTracks, setGeneratedTracks] = useState([]);
  const [error, setError] = useState(null);

  const [openaiApiKey, setOpenaiApiKey] = useState("");
  const [relationship, setRelationship] = useState("");
  const [occasion, setOccasion] = useState("");
  const [location, setLocation] = useState("US");
  const [favoriteMemory, setFavoriteMemory] = useState("");
  const [hobbiesInterests, setHobbiesInterests] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);

  useEffect(() => {
    console.log("Config received:", config);
    if (config?.openaiApiKey) {
      console.log("Setting OpenAI API key:", config.openaiApiKey);
      setOpenaiApiKey(config.openaiApiKey);
    } else {
      console.error("OpenAI API key is missing from the configuration");
      setError("OpenAI API key is missing. Please check your configuration.");
    }
  }, [config]);

  useEffect(() => {
    console.log("Current openaiApiKey state:", openaiApiKey);
  }, [openaiApiKey]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.data();
          setSubscriptionLevel(userData?.subscriptionLevel || 0);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleCreateNewAlbum = async () => {
    if (newAlbumTitle.trim()) {
      try {
        const newAlbumRef = await addDoc(collection(db, "albums"), {
          title: newAlbumTitle.trim(),
          userId: user.uid,
          createdAt: new Date(),
          updatedAt: new Date(),
          privacyLevel: 0,
          tracks: generatedTracks,
        });
        window.location.href = `/album/${newAlbumRef.id}`;
      } catch (error) {
        console.error("Error creating new album: ", error);
        setError("Failed to create album. Please try again.");
      }
    }
  };

  const generateTracks = async () => {
    if (subscriptionLevel === 0) {
      return;
    }

    console.log("Generating tracks. API Key:", openaiApiKey);
    setIsGeneratingTracks(true);
    setError(null);
    try {
      if (!openaiApiKey) {
        throw new Error("OpenAI API key is missing");
      }
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-2024-08-06",
          messages: [
            {
              role: "user",
              content: `We're creating a gift for ${relationship} that requires the recipient to answer 10 questions about themselves on the occasion of ${occasion}.
                Generate 10 questions for a ${relationship}.
                Please only generate 10 questions.  You should count them to ensure that there are only 10.
                Basic instructions: should include 10 questions that are core to interview and 5 that are specific/relevant to the additional context of the user's favorite memory of ${favoriteMemory} and their interest in ${hobbiesInterests}.
                10 questions to earn more about them as a person as it relates to ${hobbiesInterests} (format: "1. [question1] 2. [question2] ...")`
            },
          ],
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${openaiApiKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      const content = response.data.choices[0].message.content;
      console.log("Raw content:", content);
      const tracks = content
        .split('\n')
        .filter(line => line.trim() !== '')
        .map(line => {
          const match = line.match(/^\d+\.\s*(.+)/);
          return {
            title: match ? match[1].trim() : line.trim(),
            updatedAt: new Date(),
          };
        });
      console.log("Extracted tracks:", tracks);
      setGeneratedTracks(tracks);
    } catch (error) {
      console.error("Error generating tracks:", error);
      setError("Failed to generate tracks. Please check your API key and try again.");
    } finally {
      setIsGeneratingTracks(false);
    }
  };

  const handleRemoveQuestions = () => {
    setGeneratedTracks([]);
    setAccordionExpanded(false);
  };

  if (loading) {
    return (
      <ContainerBox>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
          <CircularProgress sx={{ color: colors.gold }} />
        </Box>
      </ContainerBox>
    );
  }

  return (
    <ContainerBox>
      <Helmet>
        <title>Create Album - Golden Record</title>
        <meta name="description" content="Create a new Golden Record album" />
      </Helmet>
      <Box sx={{ backgroundColor: "white", padding: { xs: 2, sm: 4 } }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Create New Album
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="albumTitle"
          label="Album Title"
          type="text"
          fullWidth
          variant="outlined"
          value={newAlbumTitle}
          onChange={(e) => setNewAlbumTitle(e.target.value)}
          sx={{ mb: 3 }}
        />

        <Accordion expanded={accordionExpanded} onChange={(e, isExpanded) => setAccordionExpanded(isExpanded)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: colors.navy, color: "white" }}>
            <Typography>✨ Let us help you generate the questions (Optional)</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: colors.navy, color: "white" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, backgroundColor: colors.darkerNavy, padding: { xs: 1, sm: 4 }, borderRadius: "10px", mb: 4 }}>
              <QuestionnaireForm
                relationship={relationship}
                setRelationship={setRelationship}
                occasion={occasion}
                setOccasion={setOccasion}
                location={location}
                setLocation={setLocation}
                favoriteMemory={favoriteMemory}
                setFavoriteMemory={setFavoriteMemory}
                hobbiesInterests={hobbiesInterests}
                setHobbiesInterests={setHobbiesInterests}
                relationshipOptions={relationshipOptions}
                occasionOptions={occasionOptions}
              />
            </Box>
            <Button
              variant="secondary"
              onClick={generateTracks}
              disabled={isGeneratingTracks || !relationship || !occasion}
              sx={{ mb: 2 }}
            >
              {isGeneratingTracks ? (
                <CircularProgress size={24} sx={{ color: colors.gold }} />
              ) : (
                "Generate Tracks"
              )}
            </Button>

            {subscriptionLevel === 0 && (
              <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                AI-generated tracks is a premium feature.
                <Link to="/profile" style={{ marginLeft: '8px', color: colors.black, fontWeight: "bold" }}>
                  Upgrade your account
                </Link> to use this feature.
              </Alert>
            )}

            {generatedTracks.length > 0 && (
              <Box>
                <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>Generated Questions (You can edit these later):</Typography>
                {generatedTracks.map((track, index) => (
                  <Typography key={index} sx={{ padding: 1, backgroundColor: colors.darkerNavy, borderRadius: "5px", mb: 1 }}>{index + 1}. {track.title}</Typography>
                ))}
                <Button
                  onClick={handleRemoveQuestions}
                  sx={{ mt: 2, color: colors.gold, textDecoration: 'underline' }}
                >
                  Nevermind, I'll create my own questions
                </Button>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Button
          variant="contained"
          onClick={handleCreateNewAlbum}
          disabled={!newAlbumTitle.trim() || !openaiApiKey}
          fullWidth
          sx={{ mt: 3 }}
        >
          Create Album
        </Button>
      </Box>
    </ContainerBox>
  );
};

export default CreateAlbum;
